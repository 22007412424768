import "viewerjs";
import "viewerjs/dist/viewer.css"

import Viewer from 'viewerjs';

const mapViewer = new Viewer(document.getElementById('map'), {
    title: 0,
    navbar: 0,
    toolbar: 0,
    inline: false,
    initialCoverage: 1,
    viewed() {
        viewer.zoomTo(1);
    },
});